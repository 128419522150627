import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'reactstrap';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { fetchWrapper } from 'utils/fetchWrapper';
import { extractWordsWithIndices, getUniqueAlphabets } from './helpers';
import style from './wordMatrix.module.scss';

const WordMatrix = (props) => {
	const letterGrid = props.watch('letterGrid');
	const wordsArray = props.watch('wordsArray');

	const [dimension, setDimension] = useState(10);
	const [grid, setGrid] = useState(Array.from({ length: dimension }, () => Array(dimension).fill('')));
	const [extractedWords, setExtractedWords] = useState([]);
	const [uniqueAlphabets, setUniqueAlphabets] = useState([]);
	const [extraWords, setExtraWords] = useState([]); // State for extra words
	const [markWordsInvalid, setMarkWordsInvalid] = useState([]); // State for invalid words
	const [wordsMeaningSentences, setWordsMeaningSentences] = useState([]); // State to store Meaning and Sentences

	const inputRefs = useRef([]);

	const handleKeyDown = (e, rowIndex, colIndex) => {
		const currentIndex = rowIndex * grid[0].length + colIndex;

		switch (e.key) {
			case 'ArrowRight':
				if (colIndex < grid[0].length - 1) {
					inputRefs.current[currentIndex + 1].focus();
				}
				break;
			case 'ArrowLeft':
				if (colIndex > 0) {
					inputRefs.current[currentIndex - 1].focus();
				}
				break;
			case 'ArrowDown':
				if (rowIndex < grid.length - 1) {
					inputRefs.current[currentIndex + grid[0].length].focus();
				}
				break;
			case 'ArrowUp':
				if (rowIndex > 0) {
					inputRefs.current[currentIndex - grid[0].length].focus();
				}
				break;
			case 'Backspace':
				e.preventDefault();
				updateGrid(rowIndex, colIndex, '');
				if (colIndex > 0) {
					const prevIndex = currentIndex - 1;
					inputRefs.current[prevIndex].focus();
				} else if (rowIndex > 0) {
					const prevIndex = currentIndex - grid[0].length;
					inputRefs.current[prevIndex].focus();
				}
				break;
			default:
				break;
		}
	};

	const updateGrid = (rowIndex, colIndex, value) => {
		const updatedGrid = grid.map((rowArr, row) => rowArr.map((cell, col) => (rowIndex === row && colIndex === col ? value : cell)));
		setGrid(updatedGrid);
	};

	const handleInputChange = (rowIndex, colIndex, value) => {
		const uppercaseValue = value.toUpperCase();

		updateGrid(rowIndex, colIndex, uppercaseValue);

		const currentIndex = rowIndex * grid[0].length + colIndex;

		if (uppercaseValue && colIndex < grid[0].length - 1) {
			inputRefs.current[currentIndex + 1].focus();
		}
	};

	const validateWords = async (words) => {
		const wordsWithValidation = await Promise.all(
			words.map(async (word) => {
				return {
					...word,
					isValid: await checkWord(word.word),
				};
			})
		);
		setExtractedWords(wordsWithValidation);
	};

	const checkWord = async (inputWord) => {
		const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${inputWord}`;

		try {
			const response = await fetch(url);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			await response.json();
			return true;
		} catch (error) {
			return false;
		}
	};
	useEffect(() => {
		if (letterGrid) {
			setGrid(letterGrid);
			setDimension(letterGrid.length);
		}
	}, []);

	const fetchExtraWords = async () => {
		const body = {
			alphabets: uniqueAlphabets,
			wordsToRemove: extractedWords.map((word) => word.word),
		};
		try {
			const response = await fetchWrapper(`/v1/admin/get-words-list-by-characters`, {
				method: 'POST',
				body,
			});
			const { data } = response;
			setExtraWords(data);
			setMarkWordsInvalid([]);

			return data;
		} catch (error) {
			console.log(`error getting extra words : ${error}`);
		}
	};
	const handleCheckboxChange = (wordId, wordText, isChecked) => {
		setMarkWordsInvalid((prevState) => {
			if (isChecked) {
				return prevState.filter((invalidWord) => invalidWord.wordId !== wordId);
			}
			const newInvalidWord = { wordId, wordText };
			if (!prevState.find((invalidWord) => invalidWord.wordId === wordId)) {
				return [...prevState, newInvalidWord];
			}
			return prevState;
		});
		props.setInvalidWordsData((prevState) => {
			if (isChecked) {
				return prevState.filter((word) => word.wordId !== wordId);
			}
			return [...prevState, { wordId, wordText }];
		});
	};

	const handleInputChangeForWordDetails = (index, field, value) => {
		setWordsMeaningSentences((prevDetails) => {
			const updatedDetails = [...prevDetails];
			updatedDetails[index] = { ...updatedDetails[index], [field]: value };
			return updatedDetails;
		});
	};
	useEffect(() => {
		inputRefs.current = inputRefs.current.slice(0, grid.length * grid[0].length);
	}, [dimension]);

	useEffect(() => {
		const fetchWordsWithIds = async () => {
			const matrix = grid.map((row) => [...row]);
			const words = extractWordsWithIndices(matrix);

			validateWords(words);

			const uniqueAlphabets = getUniqueAlphabets(words.map((item) => item.word));
			setUniqueAlphabets(uniqueAlphabets);

			const wordsArray = words.map((wordObj) => wordObj.word.toLowerCase());

			// Call the API to get IDs for the words
			const response = await fetchWrapper(`/admin/v1/get-word-details`, {
				method: 'POST',
				body: { wordList: wordsArray },
			});
			const { data } = response;
			const formattedWordsMeaningSentences = data.map((item) => ({
				word: item.text,
				meaning: item.meaning || '',
				sentence: item.examples && item.examples.length > 0 ? item.examples[0] : '',
			}));
			setWordsMeaningSentences(formattedWordsMeaningSentences);
			const formattedWordsArray = data.map((item) => ({ id: item.id, text: item.text }));

			props.setValue('letterGrid', JSON.stringify(matrix));
			props.setValue('wordsArray', JSON.stringify(formattedWordsArray));
		};

		fetchWordsWithIds();
	}, [grid]);

	useEffect(() => {
		// This debouncing is done so ensure that parallel requests get handled
		const debounceFetch = setTimeout(async () => {
			await fetchExtraWords();
		}, 500);

		return () => clearTimeout(debounceFetch);
	}, [uniqueAlphabets, extractedWords]);

	useEffect(() => {
		props.setWordsMeaningSentences(wordsMeaningSentences);
	}, [wordsMeaningSentences]);

	useEffect(() => {
		const newWordsMeaningSentences = extractedWords.map((word) => ({
			word: word.word,
			meaning: '',
			sentence: '',
		}));
		setWordsMeaningSentences(newWordsMeaningSentences);
	}, [extractedWords]);
	return (
		<>
			<div
				style={{
					visibility: 'hidden',
					width: 0,
					height: 0,
				}}
			>
				<TextAreaNew name="letterGrid" label="letterGrid" />
				<TextAreaNew name="wordsArray" label="wordsArray" />
			</div>
			<div className={style.dimensionSelect}>
				<label htmlFor="rows-select">
					Dimension:
					<select
						id="rows-select"
						value={dimension}
						onChange={(e) => {
							setDimension(Number(e.target.value));
							setGrid(Array.from({ length: Number(e.target.value) }, () => Array(Number(e.target.value)).fill('')));
						}}
					>
						{Array.from({ length: 10 }, (_, i) => i + 1)
							.slice(2)
							.map((num) => (
								<option key={num} value={num}>
									{num}
								</option>
							))}
					</select>
				</label>
			</div>

			<div style={{ display: 'flex' }}>
				<div className={style.matrixContainer}>
					{grid.map((row, rowIndex) => (
						<div className={style.matrixRow} key={rowIndex}>
							{row.map((cell, colIndex) => (
								<input
									key={`${rowIndex}-${colIndex}`}
									ref={(el) => {
										inputRefs.current[rowIndex * grid[0].length + colIndex] = el;
									}}
									type="text"
									value={cell}
									onKeyDown={(e) => {
										if (/^[A-Z]$/.test(e.key.toUpperCase())) {
											handleInputChange(rowIndex, colIndex, e.key.toUpperCase());
										}
										handleKeyDown(e, rowIndex, colIndex);
									}}
									className={`${cell ? style.matrixCellFilled : style.matrixCell}`}
								/>
							))}
						</div>
					))}
				</div>
				{extractedWords.length > 0 && (
					<div className={style.extractedWords}>
						<h3>Extracted Words</h3>
						<ul>
							{extractedWords.map((word, index) => (
								<li
									key={word}
									style={{
										borderRadius: '10px',
										backgroundColor: word.isValid ? '#bdffbf' : '#ffb0b2',
										margin: '2px',
										padding: '3px 10px',
									}}
								>
									{word.word}
								</li>
							))}
						</ul>
					</div>
				)}
				{uniqueAlphabets.length > 0 && (
					<div className={style.extractedWords}>
						<h4>Unique Alphabets</h4>
						<ul>
							{uniqueAlphabets.map((alphabet, index) => (
								<li key={index}>{alphabet}</li>
							))}
						</ul>
					</div>
				)}
				{extraWords.length > 0 && (
					<div className={style.extractedWords}>
						<h5>Extra Words</h5>
						<ul>
							{extraWords.map((wordObj) => (
								<li key={wordObj.id}>
									<label htmlFor={`word-${wordObj.id}`}>
										<input
											type="checkbox"
											id={`word-${wordObj.id}`}
											checked={!markWordsInvalid.find((invalidWord) => invalidWord.wordId === wordObj.id)}
											onChange={(e) => handleCheckboxChange(wordObj.id, wordObj.text, e.target.checked)}
										/>
										{wordObj.text}
									</label>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
			{extractedWords.length > 0 && (
				<div className={style.formContainer}>
					<h3>Add/Update Meaning & Examples</h3>
					{wordsMeaningSentences.map((wordDetail, index) => (
						<div key={index} className={style.wordForm}>
							<h3>Word : {wordDetail.word}</h3>
							<Col sm="6">
								<InputNew
									type="text"
									label="Meaning :"
									name={`meaning-${index}`}
									defaultValue={wordDetail.meaning}
									onChange={(e) => handleInputChangeForWordDetails(index, 'meaning', e.target.value)}
								/>
							</Col>
							<Col sm="6">
								<InputNew
									type="text"
									label="Sentence :"
									name={`sentence-${index}`}
									defaultValue={wordDetail.sentence}
									onChange={(e) => handleInputChangeForWordDetails(index, 'sentence', e.target.value)}
								/>
							</Col>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default WordMatrix;
