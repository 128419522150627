/* eslint-disable no-lonely-if */
import React, { useState } from 'react';
import { isEmptyArray } from 'formik';
import { SmartButtonCTAFieldArray } from 'components/SmartButtonCTAFieldArray';
import { getSanitizedVideoUrl } from 'containers/Class/helpers';
import { useEffect } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { validateExternalWebAddress } from 'reusableComponents/Form/fieldValidators';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { InputWithFileField } from 'reusableComponents/HookForm/InputWithFileFieldArray';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import If from 'reusableComponents/If';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { getSanitizedAudioUrl, isNotEmptyArray, isNotEmptyObject, parseQueryParams, showToast } from 'utils/commonHelpers';
import { CLICK_ACTION_OPTIONS, WEBSITE_URL, clickActionOptions, gptModelOptions, roleVoiceOptions } from 'utils/constants';
import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { MidjourneyImageGenerator } from 'reusableComponents/MidjourneyImageGenerator';
import { RichTextArea } from 'reusableComponents/HookForm/RichTextArea';
import { fetchWrapper } from 'utils/fetchWrapper';
import { setIsLoading } from '../ExercisesSlice';
import { fetchExerciseQuestion, updateExercise, updateExerciseQuestion } from '../actions/exercises.actions';
import ReadAlongMobilePreview from './ReadAlongMobilePreview';
import { MatchTheColumnMobilePreview } from './MatchTheColumnMobilePreview';
import { McqMobilePreview } from './McqMobilePreview';

import {
	AnagramTypesEnum,
	MatchColumnMappingTypes,
	McqMappingTypes,
	QuestionMappingEnum,
	MediaTypeEnum,
	QuestionTypesEnum,
	ReadAlongContentTypeEnum,
	TContentOnlyExerciseQuestion,
	TExerciseQuestion,
	PlayUiTypeEnum,
	QuestionUiTypeEnum,
	TConversationExerciseQuestion,
	SpeakUiTypeEnum,
} from '../utils/types';
import { SubPromptFieldArray } from './SubPrompt';
import { MatchColumnKeyValuePair } from './MatchColumnKeyValuePair';
import { AnagramItem } from './AnagramItem';
import AnagramMobileFrame from './AnagramMobileFrame';
import { validateImage } from '../utils/validateImage';
import { convertFileToWebpBlob, getImageFileFromUrl, QuestionTypeFields, QuestionUiTypeFields } from '../utils/helpers';
import WordMatrix from './Grid/wordMatrix';

const ReadAlongTypeFields = [
	{
		label: 'With Image',
		value: ReadAlongContentTypeEnum.READ_ALONG_WITH_IMAGE,
	},
	{
		label: 'Without Image',
		value: ReadAlongContentTypeEnum.READ_ALONG_WITHOUT_IMAGE,
	},
	{
		label: 'Word Meaning',
		value: ReadAlongContentTypeEnum.WORD_MEANING,
	},
];

const AnagramTypeFields = [
	{
		label: 'Jumbled Words',
		value: AnagramTypesEnum.SENTENCE,
	},
	{
		label: 'Jumbled Alphabets',
		value: AnagramTypesEnum.WORD,
	},
];
const SpeakUiTypeFields = [
	{ label: SpeakUiTypeEnum.CALL, value: SpeakUiTypeEnum.CALL },
	{ label: SpeakUiTypeEnum.CHAT, value: SpeakUiTypeEnum.CHAT },
];
interface Option {
	value: number;
	label: string;
}

const ExerciseQuestionAddEditForm = (props) => {
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const { questionId, mode, curriculumId } = parseQueryParams(props.location.search) ?? {};
	let { exerciseId } = parseQueryParams(props.location.search) ?? {};

	const dispatch = useDispatch();

	const {
		exercises: { questions, isLoading },
		userId,
	} = useSelector(({ exercises, auth }) => ({ exercises, userId: auth?.user?.id }));

	const question: TExerciseQuestion = questions?.find((question) => question?.id === questionId);
	const [areImagesValid, setAreImagesValid] = useState(true); // to keep track of whether all images are valid

	const [anagramData, setAnagramData] = useState();

	const questionType = watch('type');
	const uiType = watch('uiType');
	const clickActionType = watch('clickAction.type');

	const matchTheColumnWatch = watch(['items']);
	const mcqWatch = watch(['options', 'title']);
	const questionMapping = watch('questionMapping');

	const itemsFieldArray = useFieldArray({
		control,
		name: 'items',
	});

	const optionsFieldArray = useFieldArray({
		control,
		name: 'options',
	});

	useEffect(() => {
		checkImageInMatchColumnType();
	}, [matchTheColumnWatch.items]);

	useEffect(() => {
		checkImageInMcq();
	}, [mcqWatch.options]);

	const readAlongContentActionType = watch('contentType');
	const questionText = watch('title');
	const completeSentence = watch('completeSentence');
	const descriptionImageText = watch('descriptionImage');
	const anagramItemType = watch('anagramType');

	const [invalidWordsData, setInvalidWordsData] = useState([]);
	const [wordsMeaningSentences, setWordsMeaningSentences] = useState([]);
	useEffect(() => {
		let minimumAspectRatio = 1.76;
		let maximumAspectRatio = 2.05;

		if (questionType?.value === QuestionTypesEnum.ANAGRAM) {
			minimumAspectRatio = 1;
			maximumAspectRatio = 1;
		}

		(async () => {
			if (!descriptionImageText) {
				return;
			}

			const flag = await validateImage(descriptionImageText, {
				minimumAspectRatio,
				maximumAspectRatio,
			});

			if (flag) {
				setAreImagesValid(true);
			} else {
				setAreImagesValid(false);
				showToast('error', 'Image is of wrong dimension');
			}
		})();
	}, [descriptionImageText]);

	useEffect(() => {
		if (isNotEmptyObject(question)) {
			const options =
				question.type === QuestionTypesEnum.MCQ
					? question.options?.map((option, index) => {
							return {
								isCorrectAnswer: option.isCorrectAnswer,
								value: option.text,
								image:
									option?.media?.mediaPath && getPreviewImages({ id: question.id, mediaType: 'image', url: option.media.mediaPath }, false),
							};
					  })
					: [];

			// logic to give columns to items
			const items = [];
			if (question.type === QuestionTypesEnum.MATCH_COLUMN) {
				let key = 1;
				for (let i = 0; i < question.items.length; i += 1) {
					const item = question.items[i];
					if (parseFloat(item.key) === key) {
						items.push({
							...item,
							column: '1',
						});
						key += 1;
					} else {
						items.push({
							...item,
							column: '2',
						});
					}
				}
			}

			const responseForForm = {
				...question,
				...(question.type === QuestionTypesEnum.CONVERSATION && {
					subPrompts: question.subPrompts,
					...(question.roleVoiceId && { roleVoiceId1: roleVoiceOptions.find((option) => option.value === question.roleVoiceId) }),
					...(question.gptModel && { gptModel: gptModelOptions.find((option) => option.value === question.gptModel) }),
					...(question.speakUiType && {
						speakUiType: SpeakUiTypeFields.find((option) => option.value === question.speakUiType),
					}),
				}),
				...(question.type === QuestionTypesEnum.CONTENT_ONLY &&
					question.clickAction?.type && {
						clickAction: {
							url: question.clickAction.url,
							type: [
								...clickActionOptions,
								{ label: 'App Custom (Defaults to V2 Bottom Sheet)', value: CLICK_ACTION_OPTIONS.APP_CUSTOM },
							].find((x) => x.value === question.clickAction.type),
							functionType: question.clickAction.functionType,
							eventDescription: question.clickAction.eventDescription,
							bottomSheet: {
								...question.clickAction?.bottomSheet,
								buttons: question.clickAction?.bottomSheet?.buttons ?? [],
							},
						},
						bottomSheetImage: question.clickAction?.bottomSheet?.image?.mediaPath
							? getPreviewImages({ id: question.id, mediaType: 'image', url: question.clickAction?.bottomSheet?.image?.mediaPath }, false)
							: null,
						shouldPauseAutoNext: question.shouldPauseAutoNext,
						completeSentence: question.completeSentence,
					}),
				...(question?.voiceId && {
					roleVoiceId: roleVoiceOptions.find((option) => option.value === question?.voiceId),
				}),
				...(question?.difficulty && {
					difficulty: { label: question.difficulty, value: question.difficulty },
				}),
				...(question.type === QuestionTypesEnum.MATCH_COLUMN &&
					question?.questionMapping && {
						questionMapping: MatchColumnMappingTypes.find((option) => option.value === question?.questionMapping),
					}),
				...(question.type === QuestionTypesEnum.MCQ &&
					question?.questionMapping && {
						questionMapping: McqMappingTypes.find((option) => option.value === question?.questionMapping),
					}),
				...(question.type === QuestionTypesEnum.READ_ALONG &&
					question?.contentType && {
						contentType: ReadAlongTypeFields.find((option) => option.value === question?.contentType),
						completeSentence: question?.completeSentence,
					}),
				...(question.type === QuestionTypesEnum.MATCH_COLUMN &&
					!question.questionMapping && {
						questionMapping: MatchColumnMappingTypes[0],
					}),
				...(question.type === QuestionTypesEnum.ANAGRAM &&
					question?.anagramType && {
						anagramType: {
							label: Object.keys(AnagramTypesEnum).find((option) => option === question?.anagramType),
							value: Object.keys(AnagramTypesEnum).find((option) => option === question?.anagramType),
						},
					}),
				descriptionImage: question.descriptionImage?.mediaPath
					? getPreviewImages({ id: question.id, mediaType: 'image', url: question.descriptionImage?.mediaPath }, false)
					: null,
				...(question.type === QuestionTypesEnum.MATCH_COLUMN && {
					items: items.map((item) => {
						return {
							...item,
							value: {
								text: item.value.text,
								image: item?.value?.image
									? getPreviewImages({ id: question.id, mediaType: 'image', url: item.value.image.mediaPath }, false)
									: null,
							},
						};
					}),
				}),
				exampleImage: question.exampleImage?.mediaPath
					? getPreviewImages({ id: question.id, mediaType: 'image', url: question.exampleImage?.mediaPath }, false)
					: null,
				descriptionVideo: getSanitizedVideoUrl(question.descriptionVideo?.mediaPath),
				// string to float
				descriptionVideoAspectRatio: question.descriptionVideo?.aspectRatio,
				options,
				allowedMediaTypes:
					question.type === QuestionTypesEnum.MEDIA
						? question.allowedMediaTypes?.map((mediaType) => ({
								label: mediaType,
								value: mediaType,
						  }))
						: [],
				type: QuestionTypeFields.find((type) => type.value === question.type),
				uiType: QuestionUiTypeFields.find((type) => type.value === question?.uiType) ?? QuestionUiTypeFields[2],
			};
			hookFormMethods.reset(responseForForm);
		} else {
			if (mode !== 'add') {
				dispatch(fetchExerciseQuestion({ questionId }));
			}
		}
	}, [question, mode, questionId]);

	const onSubmit = async (data) => {
		if (!data.type) {
			return showToast('error', 'Please select question type');
		}

		if (data.type.value === QuestionTypesEnum.ANAGRAM) {
			if (!isNotEmptyArray(data.blocks)) {
				return showToast('error', 'Please add words/alphabets');
			}

			if (data.anagramType.value === 'WORD') {
				for (let index = 0; index < data.blocks.length; index += 1) {
					const block = data.blocks[index];
					if (block?.text?.length > 1) {
						return showToast('error', `Please enter only one Alphabet in testbox ${index + 1}.`);
					}
				}
			}
			if (data.anagramType.value === 'SENTENCE') {
				for (let index = 0; index < data.blocks.length; index += 1) {
					const block = data.blocks[index];
					if (block?.text?.length > 12) {
						return showToast('error', `Please enter less than 12 Characters in word ${index + 1} .`);
					}
				}
			}
		}

		if (data.type.value === QuestionTypesEnum.MCQ) {
			// remove the boxes options that are not filled
			data.options = data.options.filter((option) => option.value || option.media);

			if (data?.options?.findIndex((x) => x.isCorrectAnswer) === -1) {
				return showToast('error', 'An MCQ Question should have atleast 1 correct option.');
			}
			if (data?.options?.length < 2) {
				return showToast('error', 'Please enter atleast 2 options with text or image not both');
			}
		} else if (data.type.value === QuestionTypesEnum.MEDIA) {
			if (!data?.allowedMediaTypes?.length) {
				return showToast('error', 'Please select atleast one media type');
			}
		}

		if (data.type.value === QuestionTypesEnum.MATCH_COLUMN) {
			if (!data.items || data.items.length === 0) return showToast('error', 'Please enter atleast 1 item');
		}

		if (data.items && data.items.length > 0) {
			// make the columns alternative in list
			const colA = [];
			const colB = [];
			let key = 1;
			for (let i = 0; i < data.items.length; i += 1) {
				const item = data.items[i];
				if (parseFloat(item.key) === key) {
					colA.push(item);
					key += 1;
				} else {
					colB.push(item);
				}
			}

			// check if there are equal number of items to match
			if (colA.length !== colB.length) return showToast('error', 'There must be equal number of items in the columns');

			const items = [];
			const maxLength = Math.max(colA.length, colB.length);

			for (let i = 0; i < maxLength; i += 1) {
				if (i < colA.length) {
					items.push(colA[i]);
				}
				if (i < colB.length) {
					items.push(colB[i]);
				}
			}

			data.items = items;
		}

		// validate all images
		const columnImageFlag = await checkImageInMatchColumnType();
		const mcqImageFlag = await checkImageInMcq();
		if (!(columnImageFlag && mcqImageFlag)) return;

		dispatch(setIsLoading());
		const entityType = 'EXERCISE_QUESTION';

		const optionsWithImages = [];

		if (data.type.value === QuestionTypesEnum.MCQ) {
			for (let i = 0; i < data.options.length; i += 1) {
				if (data.options[i].media) {
					optionsWithImages.push({
						key: `options_${i}_image`,
						isMulti: false,
						withCaption: false,
						entityId: questionId ?? exerciseId, // in add form, questionId is not available, so we send exerciseId as entityId
						entityType,
						image: data.options[i].media,
					});
					data[`options_${i}_image`] = data.options[i].media;
				}
			}
		}
		// uploading image logic for match the column
		const matchColumnImages = [];
		if (data.type.value === QuestionTypesEnum.MATCH_COLUMN) {
			for (let i = 0; i < data.items.length; i += 1) {
				if (data.items[i].value.image) {
					matchColumnImages.push({
						key: `match_column_${i}_image`,
						isMulti: false,
						withCaption: false,
						entityId: questionId ?? exerciseId,
						entityType: QuestionTypesEnum.MATCH_COLUMN,
					});
					data[`match_column_${i}_image`] = data.items[i].value.image;
				}
			}
		}

		const imagesKeys = [
			{ key: 'descriptionImage', isMulti: false, withCaption: false, entityId: questionId ?? exerciseId, entityType },
			{ key: 'exampleImage', isMulti: false, withCaption: false, entityId: questionId ?? exerciseId, entityType },
			{ key: 'bottomSheetImage', isMulti: false, withCaption: false, entityId: questionId ?? exerciseId, entityType },
			...optionsWithImages,
			...matchColumnImages,
		];

		const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		if (data.type.value === QuestionTypesEnum.MATCH_COLUMN) {
			for (let i = 0; i < data.items.length; i += 1) {
				if (data.items[i].value.image && imagesUploadData[`match_column_${i}_image`]?.url) {
					const { id: mediaId, mediaType, url: mediaPath } = imagesUploadData[`match_column_${i}_image`];
					data.items[i].value.image = { mediaId, mediaType, mediaPath };
				} else {
					delete data.items[i].value.image;
				}
			}
		}

		if (data.type.value === QuestionTypesEnum.CROSSWORD) {
			// Make hanging exercise
			const promises = [];
			if (!isEmptyArray(invalidWordsData)) {
				promises.concat(
					invalidWordsData.map((invalidWord) => {
						const { wordId, wordText } = invalidWord;
						return fetchWrapper(`/v1/admin/mark-word-as-invalid`, {
							method: 'POST',
							body: { id: wordId, text: wordText },
						});
					})
				);
			}

			if (!isEmptyArray(wordsMeaningSentences)) {
				promises.push(
					fetchWrapper(`/admin/v1/set-words-meaning-and-example`, {
						method: 'POST',
						body: { words: wordsMeaningSentences },
					})
				);
			}
			await Promise.all(promises);
			if (mode === 'add') {
				const response = await fetchWrapper('/v1/admin/add-or-update-hanging-exercise', {
					method: 'PUT',
					body: {
						mode,
						exerciseId,
						curriculumId,
						updatedExercise: {
							title: '100.100',
							questions: [],
							certificateEnabled: false,
							detailedResultsEnabled: false,
							status: 'ACTIVE',
							playUiType: PlayUiTypeEnum.WORDPLAY,
						},
					},
				});
				exerciseId = response?.data?._id?.toString();
			}
		}
		dispatch(
			updateExerciseQuestion({
				userId,
				questionId,
				exerciseId,
				updatedQuestion: ({
					...question,
					...(data.difficulty?.value && { difficulty: data.difficulty.value }),
					...(uiType?.value && {
						uiType: uiType?.value,
					}),
					subPrompts: isNotEmptyArray(data.subPrompts)
						? data.subPrompts?.map((sp) => ({
								prompt: sp.prompt.trim(),
								...(sp.roleName && { roleName: sp.roleName.trim() }),
								...(sp.startingMsg && { startingMsg: sp.startingMsg.trim() }),
								msgCounter: sp.msgCounter,
						  }))
						: [],
					starsToGrant: data.starsToGrant ? parseInt(data.starsToGrant, 10) : null,
					maxRecordingDuration: data.maxRecordingDuration,
					...(question?.type === QuestionTypesEnum.READ_ALONG && {
						contentType: data.contentType ? data.contentType.value : '',
					}),
					...((data.type === QuestionTypesEnum.READ_ALONG || data.type === QuestionTypesEnum.CONTENT_ONLY) &&
						data.completeSentence && {
							completeSentence: data.completeSentence,
						}),
					...((question?.type === QuestionTypesEnum.MATCH_COLUMN || data?.type?.value === QuestionTypesEnum.MATCH_COLUMN) && {
						matchColumnType: data.matchColumnType || 'STANDARD',
						numColumns: data.numColumns ? data.numColumns : 2,
						items: data.items,
						questionMapping: data.questionMapping.value,
					}),
					...((question?.type === QuestionTypesEnum.MCQ || data?.type?.value === QuestionTypesEnum.MCQ) && {
						questionMapping: data.questionMapping.value,
						imageDescription: data.imageDescription,
					}),
					title: data.title ? data.title.trim() : '',
					...((question?.type === QuestionTypesEnum.ANAGRAM || data?.type?.value === QuestionTypesEnum.ANAGRAM) && {
						anagramType: data.anagramType.value,
						blocks: isNotEmptyArray(data.blocks) ? data.blocks : [],
						title:
							data.anagramType.value === AnagramTypesEnum.WORD
								? 'Rearrange the letters to form a word'
								: 'Rearrange the words to form a sentence',
						subtitle:
							data.subtitle ||
							(data.anagramType.value === AnagramTypesEnum.WORD
								? 'Rearrange the letters to form a word'
								: 'Rearrange the words to form a sentence'),
					}),
					...((question?.type === QuestionTypesEnum.TELEPROMPTER || data?.type?.value === QuestionTypesEnum.TELEPROMPTER) && {
						title: 'Teleprompter question',
						telepromptText: data.telepromptText,
					}),
					...(questionType.value === QuestionTypesEnum.MATCH_COLUMN && {
						title: 'Match the following',
					}),
					...((question?.type === QuestionTypesEnum.CONTENT_ONLY || data?.type?.value === QuestionTypesEnum.CONTENT_ONLY) && {
						roleName: data?.roleName?.trim(),
						shouldPauseAutoNext: data.shouldPauseAutoNext,
						completeSentence: data.completeSentence,
					}),
					voiceId: data.roleVoiceId?.value,
					...(isNotEmptyObject(data.clickAction) && {
						clickAction: {
							type: data.clickAction.type?.value,
							functionType: data.clickAction.functionType,
							url: data.clickAction.url,
							eventDescription: data.clickAction.eventDescription,
							bottomSheet: {
								...(question.type === QuestionTypesEnum.CONTENT_ONLY &&
									question?.clickAction?.bottomSheet?.audioPath && {
										audioPath: question?.clickAction?.bottomSheet?.audioPath,
									}),
								...data.clickAction.bottomSheet,
								...(data.clickAction.bottomSheet?.audioFileUrl
									? {
											audioFileUrl: data.clickAction.bottomSheet.audioFileUrl,
									  }
									: { audioFileUrl: null }),
								...(imagesUploadData?.bottomSheetImage?.url
									? {
											image: {
												mediaId: imagesUploadData?.bottomSheetImage?.id,
												mediaPath: imagesUploadData?.bottomSheetImage?.url,
												mediaType: MediaTypeEnum.IMAGE,
											},
									  }
									: { image: null }),
							},
						},
					}),
					subtitle: data.subtitle && data.subtitle.trim(),
					passageText: data.passageText?.trim() ?? '',
					passageTitle: data.passageTitle?.trim() ?? '',
					...(imagesUploadData?.descriptionImage?.url
						? {
								descriptionImage: {
									mediaId: imagesUploadData?.descriptionImage?.id,
									mediaPath: imagesUploadData?.descriptionImage?.url,
									mediaType: MediaTypeEnum.IMAGE,
								},
						  }
						: { descriptionImage: null }),
					...(imagesUploadData?.exampleImage?.url
						? {
								exampleImage: {
									mediaId: imagesUploadData?.exampleImage?.id,
									mediaPath: imagesUploadData?.exampleImage?.url,
									mediaType: MediaTypeEnum.IMAGE,
								},
						  }
						: { exampleImage: null }),
					...(data.descriptionVideo
						? {
								descriptionVideo: {
									mediaId: question?.descriptionVideo?.mediaId ?? '',
									mediaPath: data.descriptionVideo.trim(),
									mediaType: MediaTypeEnum.VIDEO,
									aspectRatio: data.descriptionVideoAspectRatio,
								},
						  }
						: { descriptionVideo: null }),
					solutionVideoUrl: data.solutionVideoUrl?.trim(),
					options: data.options?.map((option, index) => {
						return {
							_id: question?.type === QuestionTypesEnum.MCQ ? question?.options?.[index]?.['_id'] : undefined,
							text: option.value?.trim(),
							isCorrectAnswer: option.isCorrectAnswer,
							...(imagesUploadData?.[`options_${index}_image`]?.url
								? {
										media: {
											mediaId: imagesUploadData?.[`options_${index}_image`]?.id,
											mediaType: MediaTypeEnum.IMAGE,
											mediaPath: imagesUploadData?.[`options_${index}_image`]?.url,
										},
								  }
								: {}),
						};
					}),
					allowedMediaTypes: data.allowedMediaTypes?.map((mediaType) => mediaType.value) ?? undefined,
					type: data.type.value,
					...(data.type.value === QuestionTypesEnum.CONVERSATION && {
						...(data.userPrompt && { userPrompt: data.userPrompt?.trim() }),
						roleName: data.roleName?.trim(),
						roleDescription: data.roleDescription?.trim(),
						initialDialogue: data.initialDialogue?.trim(),
						maxLength: data.maxLength,
						speakUiType: data.speakUiType.value,
						roleVoiceId: data.roleVoiceId1.value,
						gptModel: data.gptModel.value,
					}),
					...(data.type.value === QuestionTypesEnum.CROSSWORD && {
						title: 'Crossword',
						letterGrid: data.letterGrid,
						status: 'ACTIVE',
						level: data.level,
					}),
				} as unknown) as Partial<TExerciseQuestion>,
				mode,
			})
		);
	};

	// check if any of the images in match the column type question of wrong dimension
	const checkImageInMatchColumnType = async () => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (matchTheColumnWatch.items) {
				for (let idx = 0; idx < matchTheColumnWatch.items.length; idx += 1) {
					const item = matchTheColumnWatch.items[idx];
					// eslint-disable-next-line no-await-in-loop
					const flag = await validateImage(item.value.image, {
						minimumAspectRatio: 1,
						maximumAspectRatio: 1,
					});

					if (!flag) {
						resolve(false);
						showToast('error', `Image no ${idx + 1} is of wrong dimensions`);
					}
				}
			}

			resolve(true);
		});
	};

	// check if any of the images in mcq type question of wrong dimension
	const checkImageInMcq = async () => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (mcqWatch.options) {
				for (let idx = 0; idx < mcqWatch.options.length; idx += 1) {
					const option = mcqWatch.options[idx];
					// eslint-disable-next-line no-await-in-loop
					const flag = await validateImage(option.media, {
						minimumAspectRatio: 1,
						maximumAspectRatio: 1,
					});

					if (!flag) {
						resolve(false);
						showToast('error', `Image no ${idx + 1} is of wrong dimensions`);
					}
				}
			}

			resolve(true);
		});
	};

	const getMatchTheFollowingList = ({ column }) => {
		if (questionType.value !== QuestionTypesEnum.MATCH_COLUMN) return;

		return itemsFieldArray.fields.map((item, idx) => {
			if (item.column !== column) return;

			// check the question format to decide to show image
			const isImageItem =
				!!item.value.image ||
				(column === '1'
					? [QuestionMappingEnum.IMAGES_TO_TEXT, QuestionMappingEnum.IMAGES_TO_IMAGES].includes(questionMapping && questionMapping.value)
					: [QuestionMappingEnum.TEXT_TO_IMAGES, QuestionMappingEnum.IMAGES_TO_IMAGES].includes(questionMapping && questionMapping.value));

			return (
				<Box key={item.id} pos="relative">
					<Row style={{ border: '1px solid #bababa', padding: '20px 0', margin: '10px 0' }}>
						<Col>
							{isImageItem && (
								<>
									<DropzoneNew label="Upload Image (100X100)px" name={`items[${idx}].value.image`} defaultValue={item.value.image} />
									<MidjourneyImageGenerator
										width="3rem"
										height="3rem"
										acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
											const imageName = `${questionId}_item_${idx}_descriptionImage`;

											const file = await getImageFileFromUrl({
												imageUrl,
												imageName: `${imageName}.png`,
											});

											const convertedFile = await getImageFileFromUrl({
												imageUrl: (await convertFileToWebpBlob({
													file,
												})) as string,
												imageName: `${imageName}.webp`,
											});

											setValue(`items[${idx}].value.image`, [convertedFile]);
										}}
									/>
								</>
							)}
							<div style={{ display: 'none' }}>
								<InputNew readOnly name={`items[${idx}].key`} required defaultValue={item.key} label="key" />
							</div>
							<InputNew
								name={`items[${idx}].value.text`}
								required={!isImageItem}
								defaultValue={item.value.text}
								label={isImageItem ? 'Image description' : 'Text input'}
								placeholder={isImageItem ? 'Image description' : 'Text input'}
								maxLength={isImageItem ? null : 12}
								helpText={isImageItem ? '' : 'Maximum 12 characters'}
							/>
						</Col>
						<Box onClick={() => itemsFieldArray.remove(idx)} cursor="pointer" pos="absolute" t="6px" r="6px">
							<span className="lnr lnr-cross" />
						</Box>
					</Row>
				</Box>
			);
		});
	};

	const MatchTheColumnAddItemButton = ({ column }) => {
		return (
			<Button
				disabled={
					itemsFieldArray.fields.filter((item) => item.column === column).length >=
					((column === '1'
						? [QuestionMappingEnum.IMAGES_TO_TEXT, QuestionMappingEnum.IMAGES_TO_IMAGES]
						: [QuestionMappingEnum.TEXT_TO_IMAGES, QuestionMappingEnum.IMAGES_TO_IMAGES]
					).includes(questionMapping && questionMapping.value)
						? 3
						: 4)
				}
				type="button"
				onClick={() => {
					itemsFieldArray.append({
						column,
						key: `${itemsFieldArray.fields.filter((f) => f.column === column).length + 1}`,
						value: {
							text: '',
							...((column === '1'
								? [QuestionMappingEnum.IMAGES_TO_TEXT, QuestionMappingEnum.IMAGES_TO_IMAGES]
								: [QuestionMappingEnum.TEXT_TO_IMAGES, QuestionMappingEnum.IMAGES_TO_IMAGES]
							).includes(questionMapping && questionMapping.value) && {
								image: {},
							}),
						},
					});
				}}
			>
				+ Add New Item
			</Button>
		);
	};

	const getQuestionForm = (question: TExerciseQuestion) => {
		if (!question) return;
		switch (question.type) {
			case QuestionTypesEnum.CONVERSATION:
				return (
					<>
						<Row>
							<Box w="100%" d="flex" jc="space-between">
								<Col sm="6">
									<SelectFieldNew
										label="Click Action Type"
										name="speakUiType"
										options={[
											{ label: 'CALL', value: 'CALL' },
											{ label: 'CHAT', value: 'CHAT' },
										]}
										defaultValue={{ label: 'CHAT', value: 'CHAT' }}
										required
									/>
								</Col>
							</Box>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="title" label="Title" required defaultValue="" />
							</Col>
							{question?.titleAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.titleAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col sm="12">
								<SelectFieldNew
									label="Voice for Scene Narration"
									name="roleVoiceId"
									options={roleVoiceOptions}
									defaultValue={roleVoiceOptions[0]}
									isDisabled
								/>
							</Col>
							<Col>
								<TextAreaNew name="subtitle" label="Scene" defaultValue="" required />
							</Col>
							{question?.subtitleAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.subtitleAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="roleDescription" label="System Prompt" required defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="userPrompt" label="User Prompt" defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<SelectFieldNew label="AI Role Voice" name="roleVoiceId1" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
							</Col>
							<Col>
								<InputNew name="roleName" label="AI Role Name" required defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="initialDialogue" label="Initial Dialogue for AI Role" required defaultValue="" />
							</Col>
							{question.type === QuestionTypesEnum.CONVERSATION && question?.initialDialogueAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.initialDialogueAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<InputNew
									type="number"
									name="maxLength"
									label="Max Conversation Length(End conversation after this)"
									required
									defaultValue={9999}
									readOnly
									max={9999}
									min={1}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<SelectFieldNew label="Chatgpt Model" name="gptModel" options={gptModelOptions} defaultValue={gptModelOptions[0]} />
							</Col>
						</Row>
						{mode === 'edit' && Array.isArray(question?.subPrompts) && question.subPrompts.length > 0 && (
							<Row>
								<Col>
									<Box>
										<h3 style={{ marginBottom: '1rem' }}>Prompts</h3>
										<SubPromptFieldArray
											{...hookFormMethods}
											control={control}
											register={register}
											watch={watch}
											setValue={setValue}
											errors={errors}
											promptsData={question?.subPrompts}
										/>
									</Box>
								</Col>
							</Row>
						)}
					</>
				);

			case QuestionTypesEnum.MCQ:
				return (
					<>
						<Row>
							<Col sm="8">
								<Row>
									<Col sm="6">
										<SelectFieldNew
											label="Select question format"
											name="questionMapping"
											options={McqMappingTypes}
											defaultValue={McqMappingTypes[0]}
											required
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<InputNew name="title" label="Question Text" defaultValue="" placeholder="Question Text" />
									</Col>
								</Row>
								<Row>
									<Col>
										<TextAreaNew name="subtitle" label="Narration Text" defaultValue="" />
									</Col>
								</Row>
								<Row>
									<Col>
										<DropzoneNew label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
										<InputNew name="imageDescription" label="Image Description" defaultValue="" placeholder="Image Description" />
										<MidjourneyImageGenerator
											width="3rem"
											height="3rem"
											acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
												const imageName = `${questionId}_descriptionImage`;

												const file = await getImageFileFromUrl({
													imageUrl,
													imageName: `${imageName}.png`,
												});

												const convertedFile = await getImageFileFromUrl({
													imageUrl: (await convertFileToWebpBlob({
														file,
													})) as string,
													imageName: `${imageName}.webp`,
												});

												setValue('descriptionImage', [convertedFile]);
											}}
										/>
									</Col>
								</Row>

								<Row>
									<Col sm="6">
										<SelectFieldNew
											label="Difficulty"
											name="difficulty"
											options={Array.from({ length: 10 }, (_, index) => ({
												label: index + 1,
												value: index + 1,
											}))}
											defaultValue={{
												label: 5,
												value: 5,
											}}
										/>
									</Col>
								</Row>

								<Row>
									{optionsFieldArray.fields.map((option, idx) => {
										const isImageItem = [
											QuestionMappingEnum.IMAGES_TO_IMAGES,
											QuestionMappingEnum.SOUND_TO_IMAGES,
											QuestionMappingEnum.TEXT_TO_IMAGES,
										].includes(questionMapping && questionMapping.value);

										return (
											<Box
												key={option.id || idx}
												pos="relative"
												style={{
													width: '50%',
												}}
											>
												<Row style={{ border: '1px solid #bababa', padding: '20px 0', margin: '10px 0', position: 'relative' }}>
													<Col>
														{(isImageItem || option.image) && (
															<>
																<DropzoneNew label="Upload Image (150X150)px" name={`options[${idx}].media`} defaultValue={option.image} />

																<MidjourneyImageGenerator
																	width="3rem"
																	height="3rem"
																	acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
																		const imageName = `${questionId}_option_${idx}`;

																		const file = await getImageFileFromUrl({
																			imageUrl,
																			imageName: `${imageName}.png`,
																		});

																		const convertedFile = await getImageFileFromUrl({
																			imageUrl: (await convertFileToWebpBlob({
																				file,
																			})) as string,
																			imageName: `${imageName}.webp`,
																		});

																		setValue(`options[${idx}].media`, [convertedFile]);
																	}}
																/>
															</>
														)}
														<Checkbox label="Is Correct?" name={`options[${idx}].isCorrectAnswer`} checked={option?.isCorrectAnswer} />
														<InputNew
															required={!isImageItem}
															maxLength={80}
															name={`options[${idx}].value`}
															defaultValue={option.value}
															label={isImageItem ? 'Image description' : 'Text input'}
															placeholder={isImageItem ? 'Image description' : 'Text input'}
														/>
													</Col>
													<Box onClick={() => optionsFieldArray.remove(idx)} cursor="pointer" pos="absolute" t="6px" r="6px">
														<span className="lnr lnr-cross" />
													</Box>
												</Row>
											</Box>
										);
									})}
								</Row>

								<Row
									style={{
										justifyContent: 'center',
									}}
								>
									<Button
										disabled={optionsFieldArray.fields.length >= 4}
										type="button"
										onClick={() => {
											optionsFieldArray.append({});
										}}
									>
										+ Add New Option
									</Button>
								</Row>
							</Col>
							<Col sm="4">
								<McqMobilePreview
									title={mcqWatch.title}
									image={descriptionImageText}
									questionOptions={
										mcqWatch.options &&
										mcqWatch.options.map((option, idx) => {
											return {
												...option,
											};
										})
									}
								/>
							</Col>
						</Row>
					</>
				);

			case QuestionTypesEnum.MATCH_COLUMN:
				return (
					<>
						<Row>
							<Col sm="8">
								<Row>
									<Col sm="6">
										<SelectFieldNew
											label="Select question format"
											name="questionMapping"
											options={MatchColumnMappingTypes}
											defaultValue={MatchColumnMappingTypes.find((option) => option.value === question.questionMapping)}
											// isDisabled={mode === 'edit'}
											required
										/>
									</Col>
								</Row>
								{MatchColumnMappingTypes.map((type) => type.value).includes(questionMapping && questionMapping.value) && (
									<>
										<Row style={{ justifyContent: 'center', padding: '20px 0' }}>
											<h3>Add Matching Items</h3>
										</Row>

										<Row>
											<Col sm="6">
												<h3 style={{ textAlign: 'center' }}>A</h3>
												{getMatchTheFollowingList({ column: '1' })}
												<Row style={{ justifyContent: 'center' }}>
													<MatchTheColumnAddItemButton column="1" />
												</Row>
											</Col>
											<Col sm="6">
												<h3 style={{ textAlign: 'center' }}>B</h3>
												{getMatchTheFollowingList({ column: '2' })}
												<Row style={{ justifyContent: 'center' }}>
													<MatchTheColumnAddItemButton column="2" />
												</Row>
											</Col>
										</Row>
									</>
								)}
								<Row>
									<Col>
										<TextAreaNew name="subtitle" label="Narration Text" defaultValue="" />
									</Col>
									{question?.subtitleAudio && (
										<Col style={{ display: 'flex', alignItems: 'center' }}>
											<Box d="flex" ai="center" jc="center" w="100%">
												<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.subtitleAudio)}</p>
											</Box>
										</Col>
									)}
								</Row>
								<Row>
									<Col sm="6">
										<SelectFieldNew
											label="Difficulty"
											name="difficulty"
											options={Array.from({ length: 10 }, (_, index) => ({
												label: index + 1,
												value: index + 1,
											}))}
											defaultValue={{
												label: 5,
												value: 5,
											}}
										/>
									</Col>
								</Row>
							</Col>
							<Col sm="4">
								<MatchTheColumnMobilePreview
									itemList={
										matchTheColumnWatch.items &&
										matchTheColumnWatch.items.length > 0 &&
										itemsFieldArray.fields.map((field, idx) => {
											return {
												column: field.column,
												key: field.key,
												value: {
													...matchTheColumnWatch.items[idx]?.value,
												},
											};
										})
									}
								/>
							</Col>
						</Row>
					</>
				);
			case QuestionTypesEnum.READ_ALONG:
				return (
					<>
						<Row className="d-flex">
							<Col className=".col-xs-6">
								<Col sm="12">
									<TextAreaNew
										maxLength={200}
										required
										name="completeSentence"
										label="Complete Sentence :"
										defaultValue="Enter the complete Sentence."
									/>
								</Col>
								<Col sm="12">
									<TextAreaNew
										maxLength={200}
										required
										name="title"
										label="Question Text:"
										defaultValue="Enter the primary text that the user will read aloud."
									/>
								</Col>
								<Col style={{ maxWidth: '350px' }}>
									<SelectFieldNew
										label="Content Type"
										name="contentType"
										options={ReadAlongTypeFields}
										defaultValue={ReadAlongTypeFields[0]}
										required
									/>
								</Col>
								<Col sm="12">
									<TextAreaNew
										name="subtitle"
										label="Narration Text"
										placeholder="Provide a background narration if different from the Question Text."
									/>
								</Col>
								{readAlongContentActionType?.value === ReadAlongContentTypeEnum.READ_ALONG_WITH_IMAGE && (
									<Col sm="12">
										<DropzoneNew
											height="600"
											width="900"
											label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)"
											name="descriptionImage"
											required
										/>
										<MidjourneyImageGenerator
											width="3rem"
											height="3rem"
											acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
												const imageName = `${questionId}_descriptionImage`;

												const file = await getImageFileFromUrl({
													imageUrl,
													imageName: `${imageName}.png`,
												});

												const convertedFile = await getImageFileFromUrl({
													imageUrl: (await convertFileToWebpBlob({
														file,
													})) as string,
													imageName: `${imageName}.webp`,
												});

												setValue('descriptionImage', [convertedFile]);
											}}
										/>
									</Col>
								)}
								<Col sm="12">
									<SelectFieldNew
										label="Difficulty Level"
										name="difficulty"
										options={Array.from({ length: 10 }, (_, index) => ({
											label: index + 1,
											value: index + 1,
										}))}
										defaultValue={{
											label: 5,
											value: 5,
										}}
										required
									/>
								</Col>
							</Col>
							<Col>
								<ReadAlongMobilePreview heading={questionText} image={descriptionImageText} completeSentence={completeSentence} />
							</Col>
						</Row>
					</>
				);
			case QuestionTypesEnum.ANAGRAM:
				return (
					<>
						<Row className="d-flex">
							<Col className=".col-6">
								<Col style={{ maxWidth: '350px' }}>
									<SelectFieldNew
										label="Content Type"
										name="anagramType"
										options={AnagramTypeFields}
										defaultValue={AnagramTypeFields[0]}
										isDisabled={mode === 'edit'}
										required
									/>
								</Col>
								<Col sm="12">
									<AnagramItem
										{...hookFormMethods}
										control={control}
										fieldName="blocks"
										anagramItemType={anagramItemType && anagramItemType.value}
										setAnagramData={setAnagramData}
									/>
								</Col>
								<Col>
									<DropzoneNew label="Associated Image (1:1)" name="descriptionImage" />
									<InputNew name="imageDescription" label="Image Description" defaultValue="" placeholder="Image Description" />
									<MidjourneyImageGenerator
										width="3rem"
										height="3rem"
										acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
											const imageName = `${questionId}_descriptionImage`;

											const file = await getImageFileFromUrl({
												imageUrl,
												imageName: `${imageName}.png`,
											});

											const convertedFile = await getImageFileFromUrl({
												imageUrl: (await convertFileToWebpBlob({
													file,
												})) as string,
												imageName: `${imageName}.webp`,
											});

											setValue('descriptionImage', [convertedFile]);
										}}
									/>
								</Col>
								<Col sm="12">
									<TextAreaNew
										name="subtitle"
										label="Narration Text"
										defaultValue={
											// eslint-disable-next-line no-nested-ternary
											anagramItemType
												? anagramItemType.value === AnagramTypesEnum.WORD
													? 'Rearrange the letters to form a word'
													: 'Rearrange the words to form a sentence'
												: ''
										}
										placeholder="This is optional and is used for narration in the background."
									/>
								</Col>
								<Col sm="12">
									<SelectFieldNew
										label="Difficulty Level"
										name="difficulty"
										options={Array.from({ length: 10 }, (_, index) => ({
											label: index + 1,
											value: index + 1,
										}))}
										defaultValue={{
											label: 5,
											value: 5,
										}}
										required
									/>
								</Col>
							</Col>
							<Col className=".col-6">
								<AnagramMobileFrame anagramData={anagramData} anagramItemType={anagramItemType && anagramItemType.value} />
							</Col>
						</Row>
					</>
				);

			case QuestionTypesEnum.TELEPROMPTER:
				return (
					<>
						{/* <Row>
							<Col>
								<TextAreaNew name="title" label="Title" required defaultValue="" />
							</Col>
						</Row> */}
						<Row>
							<Col>
								<Box d="flex" ai="center" jc="flex-start">
									<InputNew
										min={5}
										type="number"
										label="Maximum Recording Duration (In Seconds)"
										name="maxRecordingDuration"
										defaultValue={60}
									/>
								</Box>
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="telepromptText" label="Teleprompter text :-" required minLength={5} maxLength={180} />
							</Col>
						</Row>
					</>
				);
			case QuestionTypesEnum.CROSSWORD:
				return (
					<>
						<Row className="d-flex">
							<Col className=".col-xs-12">
								<Col sm="12">
									<InputNew label="Level" name="level" required />
								</Col>
							</Col>
						</Row>
						<Row className="d-flex">
							<Col className=".col-xs-12">
								<Col sm="12">
									<WordMatrix
										watch={watch}
										setValue={setValue}
										setInvalidWordsData={setInvalidWordsData}
										setWordsMeaningSentences={setWordsMeaningSentences}
									/>
								</Col>
							</Col>
						</Row>
					</>
				);
			default:
				// content only questions

				if (uiType?.value === QuestionUiTypeEnum.SENTENCE) {
					return (
						<>
							<Row>
								<Col sm="12">
									<InputNew name="title" label="Word to Read" defaultValue="" placeholder="Word to Read" required />
								</Col>
								<Col style={{ maxWidth: '350px' }}>
									<SelectFieldNew
										label="Content Type"
										name="contentType"
										options={ReadAlongTypeFields.slice(0, 2)}
										defaultValue={ReadAlongTypeFields[1]}
										required
									/>
								</Col>

								{readAlongContentActionType?.value === ReadAlongContentTypeEnum.READ_ALONG_WITH_IMAGE && (
									<Col sm="12">
										<DropzoneNew
											height="600"
											width="900"
											label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)"
											name="descriptionImage"
											required
										/>
									</Col>
								)}
							</Row>
						</>
					);
				}

				if (uiType?.value === QuestionUiTypeEnum.VOCAB) {
					return (
						<>
							<Row>
								<Col sm="6">
									<SelectFieldNew
										label="Select question format"
										name="questionMapping"
										options={McqMappingTypes.slice(0, 2)}
										defaultValue={McqMappingTypes[0]}
										required
									/>
								</Col>
								<Col sm="12">
									<InputNew name="title" label="Word" defaultValue="" placeholder="Question Text" required />
								</Col>
								<Col sm="12">
									<TextAreaNew name="passageText" label="Meaning" defaultValue="" />
								</Col>
								<Col sm="12">
									<SelectFieldNew
										label="Role Voice"
										name="roleVoiceId"
										options={roleVoiceOptions}
										defaultValue={roleVoiceOptions[0]}
										required
									/>
								</Col>
								<Col sm="12">
									<TextAreaNew name="passageTitle" label="Parts of Speech" defaultValue="" />
								</Col>
								<Col>
									<DropzoneNew label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
								</Col>
							</Row>
						</>
					);
				}

				return (
					<>
						{question?.type === QuestionTypesEnum.CONTENT_ONLY && (
							<Row>
								<Col sm="12">
									<TextAreaNew
										maxLength={200}
										required
										name="completeSentence"
										label="Complete Sentence :"
										defaultValue="Enter the complete Sentence."
									/>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<TextAreaNew name="title" label="Title" defaultValue="" />
							</Col>
							{question?.titleAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.titleAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Box w="100%" d="flex" jc="space-between">
								{questionType?.value === QuestionTypesEnum.CONTENT_ONLY && (
									<Col sm="6">
										<InputNew label="Role Name" name="roleName" />
									</Col>
								)}
								<Col sm="6">
									<SelectFieldNew label="Role Voice" name="roleVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
								</Col>
							</Box>
						</Row>
						{questionType?.value === QuestionTypesEnum.CONTENT_ONLY && (
							<>
								<Row>
									<Col>
										<Checkbox label="Should pause simulation auto next ?" name="shouldPauseAutoNext" />
									</Col>
								</Row>
								<Row>
									<Box w="100%" d="flex" jc="space-between">
										<Col sm="6">
											<SelectFieldNew
												label="Click Action Type"
												name="clickAction.type"
												options={[
													...clickActionOptions,
													{ label: 'App Custom (Defaults to V2 Bottom Sheet)', value: CLICK_ACTION_OPTIONS.APP_CUSTOM },
												]}
											/>
										</Col>
										<If
											condition={clickActionType?.value === CLICK_ACTION_OPTIONS.APP_CUSTOM}
											then={
												<Col sm="4">
													<InputNew
														label="Function Type (Default)"
														name="clickAction.functionType"
														defaultValue="V2_OPEN_BOTTOM_SHEET"
														isClickDisabled
													/>
												</Col>
											}
										/>
										<Box w="100%" d="flex" ai="center" jc="flex-start">
											<If
												condition={clickActionType?.value === CLICK_ACTION_OPTIONS.APP_LINK}
												then={<p style={{ paddingLeft: '15px', marginBottom: '15px' }}>{WEBSITE_URL}/</p>}
											/>
											<If
												condition={
													![CLICK_ACTION_OPTIONS.DISABLED, CLICK_ACTION_OPTIONS.APP_CUSTOM, undefined].includes(clickActionType?.value)
												}
												then={
													<Col sm="6">
														<InputNew
															label="Click Action URL"
															name="clickAction.url"
															validate={(val) => {
																if (clickActionType?.value === CLICK_ACTION_OPTIONS.EXTERNAL_LINK) {
																	return validateExternalWebAddress(val) || true;
																}
																return true;
															}}
															defaultValue={
																(question as TContentOnlyExerciseQuestion)?.clickAction?.url?.charAt(0) === '/'
																	? (question as TContentOnlyExerciseQuestion)?.clickAction?.url.slice(1)
																	: (question as TContentOnlyExerciseQuestion)?.clickAction?.url
															}
														/>
													</Col>
												}
											/>
										</Box>
									</Box>
									<If
										condition={clickActionType?.value !== CLICK_ACTION_OPTIONS.DISABLED}
										then={
											<Col sm="4">
												<InputNew
													label="Event Description"
													name="clickAction.eventDescription"
													defaultValue={(question as TContentOnlyExerciseQuestion)?.clickAction?.eventDescription}
												/>
											</Col>
										}
									/>
								</Row>
							</>
						)}
						<If
							condition={clickActionType?.value === CLICK_ACTION_OPTIONS.APP_CUSTOM}
							then={
								<Row>
									<Col sm="12">
										<Row>
											<Col sm="6">
												<InputNew label="Bottom Sheet Heading" name="clickAction.bottomSheet.heading" />
											</Col>
											<Col sm="6">
												<InputNew label="Bottom Sheet Description" name="clickAction.bottomSheet.description" />
											</Col>
											<Col sm="6">
												<InputNew label="Bottom Sheet Height (Fixed in px)" name="clickAction.bottomSheet.fixedHeight" />
											</Col>
											<Col sm="6">
												<InputNew label="Bottom Sheet Audio Text" name="clickAction.bottomSheet.audioText" />
											</Col>
											<Col sm="6">
												<InputNew label="Bottom Sheet Audio File Url" name="clickAction.bottomSheet.audioFileUrl" />
											</Col>
										</Row>
										<Row>
											<Col>
												<DropzoneNew label="Bottom Sheet Image" name="bottomSheetImage" />
											</Col>
										</Row>
										<SmartButtonCTAFieldArray
											{...hookFormMethods}
											control={control}
											errors={errors}
											register={register}
											watch={watch}
											setValue={setValue}
											buttonsData={(question as TContentOnlyExerciseQuestion)?.clickAction?.bottomSheet?.buttons ?? []}
											fieldName="clickAction.bottomSheet.buttons"
										/>
									</Col>
								</Row>
							}
						/>
						<Row>
							<Col>
								<TextAreaNew name="subtitle" label="Sub title (Background narration audio)" defaultValue="" />
							</Col>
							{question?.subtitleAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.subtitleAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<TextAreaNew
									name="passageTitle"
									label={uiType === QuestionUiTypeEnum.VOCAB ? 'Parts of Speech' : 'Passage title'}
									{...(uiType === QuestionUiTypeEnum.VOCAB && { required: true })}
									defaultValue=""
								/>
							</Col>
							{question?.passageTitleAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.passageTitleAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<TextAreaNew
									name="passageText"
									label={uiType === QuestionUiTypeEnum.VOCAB ? 'Word Meaning' : 'Passage Text'}
									{...(uiType === QuestionUiTypeEnum.VOCAB && { required: true })}
									defaultValue=""
								/>
							</Col>
							{question?.passageTextAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.passageTextAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<DropzoneNew label="Description Image" name="descriptionImage" />
							</Col>
						</Row>
						{/* <Row>
							<Col>
								<DropzoneNew
									label="Output Image - Question Output Image (For Video Art Exercise Only) Question Steps (For Non-Video Art Exercise Only)"
									name="exampleImage"
								/>
							</Col>
						</Row> */}
						{/* <Row>
							<Col>
								<InputNew label="Description Video" name="descriptionVideo" />
								<InputNew
									type="text"
									pattern={{ value: /^\d+:\d+$/, message: 'Please enter a valid aspect ratio' }}
									label="Description Video Aspect Ratio(example 16:9)"
									name="descriptionVideoAspectRatio"
								/>
							</Col>
						</Row> */}
						{/* <Row>
							<Col>
								<Box d="flex" ai="center" jc="flex-start">
									<p style={{ paddingRight: '15px' }}>{process.env.REACT_APP_CLOUDFRONT_VIDEO_PREFIX}</p>
									<InputNew label="Solution Video" name="solutionVideoUrl" placeholder="/your-file-path" />
								</Box>
							</Col>
						</Row> */}
						<Row>
							<Col>
								<Box d="flex" ai="center" jc="flex-start">
									<InputNew type="number" min={1} label="Stars to Grant" name="starsToGrant" />
								</Box>
							</Col>
						</Row>
						{questionType?.value === QuestionTypesEnum.MATCH_COLUMN && (
							<>
								<Row>
									<MatchColumnKeyValuePair {...hookFormMethods} control={control} fieldName="items" />
								</Row>
								<Row>
									<Col sm="6">
										<InputNew type="number" min={2} max={4} required label="Number of columns" name="numColumns" />
									</Col>
									<Col sm="6">
										<SelectFieldNew
											label="Match the Column Type"
											name="matchColumnType"
											options={Object.entries(MatchColumnMappingTypes).map((ele) => ({ label: ele[0], value: ele[1] }))}
											required
										/>
									</Col>
								</Row>
							</>
						)}
						{questionType?.value === QuestionTypesEnum.ANAGRAM && (
							<>
								<Row>
									<AnagramItem
										{...hookFormMethods}
										control={control}
										fieldName="blocks"
										setAnagramData={setAnagramData}
										anagramItemType={anagramItemType.value}
									/>
								</Row>
								<Row>
									<Col sm="6">
										<SelectFieldNew
											label="Anagram Type"
											name="anagramType"
											options={Object.entries(AnagramTypesEnum).map((ele) => ({ label: ele[0], value: ele[1] }))}
											required
										/>
									</Col>
								</Row>
							</>
						)}
						{questionType?.value === QuestionTypesEnum.MCQ && (
							<Row>
								<Col>
									<Label> Options </Label>
									<InputWithFileField
										label="Option"
										radioLabel="Is Correct?"
										name="options"
										miniumNumberOfFields={2}
										maximumNumberOfFields={4}
									/>
								</Col>
							</Row>
						)}
						{questionType?.value === QuestionTypesEnum.MEDIA && (
							<Row>
								<Col>
									<SelectFieldNew
										label="Allowed Media Types"
										name="allowedMediaTypes"
										options={Object.entries(MediaTypeEnum).map((ele) => ({ label: ele[0], value: ele[1] }))}
										isMulti
										required
									/>
								</Col>
							</Row>
						)}
					</>
				);
		}
	};

	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />
			<CardBody>
				<h3>{mode === 'edit' ? 'Question Edit' : 'Create Question'}</h3>
				<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
					<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col style={{ maxWidth: '250px' }}>
									<SelectFieldNew
										label="Select Question Type"
										name="type"
										options={QuestionTypeFields}
										defaultValue={QuestionTypeFields.find((option) => option.value === question?.type)}
										isDisabled={mode === 'edit'}
										required
									/>
								</Col>
								{questionType?.value === QuestionTypesEnum.CONTENT_ONLY && (
									<Col style={{ maxWidth: '250px' }}>
										<SelectFieldNew
											label="Select Question UI Type"
											name="uiType"
											options={QuestionUiTypeFields}
											defaultValue={QuestionUiTypeFields.find((option) => option.value === question?.uiType) ?? QuestionUiTypeFields[2]}
											isDisabled={mode === 'edit'}
											required
										/>
									</Col>
								)}
							</Row>
							{getQuestionForm(({
								type: questionType?.value,
								uiType: uiType?.value,
								subPrompts: (question as TConversationExerciseQuestion)?.subPrompts,
							} as unknown) as TExerciseQuestion)}
						</Box>
						<Button type="submit" disabled={!areImagesValid}>
							{mode === 'edit' ? 'Update' : 'Add'}
						</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default ExerciseQuestionAddEditForm;
